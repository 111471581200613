import { EMPTY } from './util';
import { initializeMixedContentListener } from './mixed_content';
import $ from 'jquery';

export function buildBootstrapModalContainer(modalId, modalLoading) {
  const $modal = $('<div class="modal fade" tabindex="-1" role="dialog"></div>')
    .attr('id', modalId)
    .appendTo('body');

  if (modalLoading) {
    $modal.html('<div class="ajax_progress">loading...</div>');
  }

  return $modal;
}

/* function to generate bootstrap-style modals on the fly */
export function buildBootstrapModal(modalId, modalLabel, modalUrl, modalFooter, modalClass) {
  // Make sure not to create the same modal twice
  if ($(`#${modalId}`).length) {
    return null;
  }

  // Check for HTML passed in for Footer
  const safeModalFooter = typeof modalFooter !== 'undefined' ? modalFooter : '';

  // Check for CSS Classname passed in
  const safeModalClass = typeof modalClass !== 'undefined' ? modalClass : '';

  // Build Modal (Bootstrap 3)
  const $modal = $('<div class="modal fade" tabindex="-1" role="dialog"></div>').appendTo('body');
  const $content = $modal.find('.modal-content');
  const $footer = $('<div class="modal-footer"></div>').appendTo($content);

  if (EMPTY.test(safeModalFooter)) {
    // No footer supplied, build default footer
    $footer.append(
      '<button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>',
    );

    if (!EMPTY.test(modalLabel)) {
      $footer.append(
        `<a id="${modalId}_link" href="${modalUrl}" class="btn btn-info">${modalLabel}</a>`,
      );
    }
  } else {
    // Footer supplied
    //   Note: modal_url and modal_label are ignored in the case where the footer is supplied
    $footer.append(safeModalFooter);
  }

  // Add Modal ID
  $modal.attr('id', modalId);

  // Add Modal CSS Class
  if (!EMPTY.test(safeModalClass)) {
    $modal.addClass(safeModalClass);
  }

  return $modal;
}

/* function to launch a bootstrap-style modal */
export function showBootstrapModal(modalId, killOnHide) {
  const $modal = $(`#${modalId}`);
  if (!$modal.length) {
    return;
  }

  // Show Modal
  $modal.modal('show');

  // Option to automatically delete the Modal when hidden
  const safeKillOnHide = typeof killOnHide !== 'undefined' ? killOnHide : false;
  if (safeKillOnHide) {
    $modal.on('hidden.bs.modal', () => {
      $modal.remove();
    });
  }
}

/* function to close and remove bootstrap modal from dom */
export function killBootstrapModal(modalId) {
  const $modal = $(`#${modalId}`);
  if (!$modal.length) {
    return;
  }

  $modal.on('hidden.bs.modal', () => {
    $modal.remove();
  });
  $modal.modal('hide');
}

/* function to either create or launch an ajax bootstrap modal */
export function ajaxBootstrapModal(ajaxUrl, modalId, hiddenCallback, attachEventsCallback) {
  let $modal = $(`#${modalId}`);

  if (!$modal.length) {
    // Modal doesn't exist in DOM; Build New Modal

    // Build Modal Container (the rest of the modal comes from ajax response)
    $modal = buildBootstrapModalContainer(modalId, true);

    // Request Modal Content via AJAX
    /* eslint-disable sort-keys */
    $.ajax({
      url: ajaxUrl,
      cache: false,
      dataType: 'html',
      success(data) {
        $modal.html(data);
        if (typeof attachEventsCallback === 'function') {
          attachEventsCallback($modal);
        }
        $modal.modal('show');
        initializeMixedContentListener();
      },
    });
    /* eslint-enable sort-keys */
  } else {
    // Modal exists in DOM
    $modal.modal('show');
    initializeMixedContentListener();
  }

  // Check for Hidden Callback
  if (typeof hiddenCallback === 'function') {
    $modal.on('hidden.bs.modal', hiddenCallback);
  }
}
