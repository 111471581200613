/* eslint-disable eqeqeq */

import {
  ajaxBootstrapModal,
  buildBootstrapModalContainer,
  showBootstrapModal,
} from './bootstrap_modal';
import { EMPTY } from './util';
import { initTitleIssueSelector } from './title_issue_selector';
import $ from 'jquery';
import CustomDomains from './classes/CustomDomains';
import PopupModal2 from './classes/PopupModalV2';

export function killAlert(id) {
  // sets a record for the alert id so it won't display again
  $.ajax({ type: 'POST', url: `/users/killAlert/${id}` });
  $('#flash_message').fadeOut();
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Deprecated Code; do not use..
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function getQueryStringByName(name, url) {
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function openPopupModal2(caption, url, opts = {}) {
  const options = {
    header: caption,
    method: 'ajax',
  };

  // Old thickbox implementation relied on url params. Parse them, and use to configure the modal:
  // accepted url params for config
  const inline = getQueryStringByName('inlineId', url);
  const width = getQueryStringByName('width', url);
  const height = getQueryStringByName('height', url);
  const iframe = getQueryStringByName('modal_iframe', url);

  // Sets the width of the modal (add some extra to mimic the look/feel of thickbox)
  if (width) {
    options.width = parseInt(width, 10) + 20;
  }

  // Sets the height of the modal (add some extra to mimic the look/feel of thickbox)
  if (height) {
    options.height = parseInt(height, 10) + 20;
  }

  // URL param indicated if the modal should iframe in content vs ajax it
  if (iframe) {
    options.method = 'iframe';
  }

  // Inline content means we're faking an ajax call by pulling in HTML from elsewhere on the page
  if (inline) {
    const content = $(`#${inline}`).html();
    options.content = content;
    options.method = 'text';
  }

  // Allow for user overrides if needed in specific scinarios
  Object.assign(options, opts);

  // Lastly, if we're loading in an iframe, add/set the iframe class to fix modal padding
  if (options.method === 'iframe') {
    options.className = options.className
      ? (options.className += ' modal-iframe')
      : (options.className = ' modal-iframe');
  }

  return PopupModal2.content(url, options);
}

// Deprecated
export function time() {
  return new Date().getTime();
}

/*
    JavaScript HTML encode - for example, safe for picking up values from HTML attributes and using them to build up HTML via JS
    NOT for use within tags such as script, in the context of event handlers, etc.
*/
export function jhe(str) {
  return $('<div/>').text(str).html();
}

/* preview creation */
export function initPreviewIssue() {
  if ($('#IssueId').length > 0 || typeof window.g_title_id !== 'undefined') {
    const tooltip =
      $('#IssueId').length > 0
        ? `preview your ${window.AppGlobals.envIssue}`
        : `preview your ${window.AppGlobals.envTitle}`;
    $('<div/>')
      .attr('id', 'preview_issue')
      .html("<i class='icon-eye-open icon-grey icon-large'></i>")
      .attr('title', tooltip)
      .appendTo('body')
      .bind('click', () => {
        previewIssue();
      })
      .tooltip({ placement: 'left' });
  }
}

/* extract page number from pageId given possible pageOffset */
export function getPageNum(elem) {
  const el = document.getElementById(elem);
  let pageNum = el.options[el.selectedIndex].text;
  // clean text of pageOffset
  const tmp = pageNum.indexOf('[');
  if (tmp > 0) {
    pageNum = pageNum.substring(0, tmp);
  }
  return parseInt(pageNum, 10);
}

//----------------------------------------
// load popup window
//----------------------------------------

export function loadPage(url, height, width, focus, scroll, close) {
  /* eslint-disable no-param-reassign */
  if (typeof focus === 'undefined') {
    focus = false;
  }
  if (typeof scroll === 'undefined') {
    scroll = 0;
  }
  if (typeof close === 'undefined') {
    close = false;
  }
  /* eslint-enable no-param-reassign */
  let w = 480;
  let h = 340;
  const browserName = navigator.appName;
  if (browserName == 'Netscape') {
    w = window.innerWidth;
    h = window.innerHeight;
  } else if (browserName == 'Microsoft Internet Explorer') {
    w = document.body.clientWidth;
    h = document.body.clientHeight;
  }

  const popW = width;
  const popH = height;
  const leftPos = (w - popW) / 2;
  const topPos = (h - popH) / 2;

  const popupWin = window.open(
    url,
    'popupWin',
    `${'toolbars=0, ' + 'scrollbars='}${scroll}, ` +
      'location=0, status=0, menubars=0,' +
      `resizable=1, width=${width}, height=${height}, left=${leftPos}, top=${topPos}`,
  );

  if (focus && popupWin) {
    popupWin.focus();
  }
  // detect popup block
  if (!popupWin) {
    alert(
      'We have detected that you are using popup blocking software.\nPlease "allow popups from this website" and try your selection again.',
    );
  } else if (close) {
    popupWin.close();
  }
}

export function previewIssue() {
  let url = '';
  if ($('#IssueId').length > 0 && $('#IssueId').val() > 0) {
    url = `/issues/preview/i/${$('#IssueId').val()}`;
    if ($('#PageId').length > 0 && $('#PageId').val() > 0) {
      url += `/${getPageNum('PageId')}`;
    }
  } else if (typeof window.g_title_id !== 'undefined') {
    url = `/issues/preview/t/${window.g_title_id}`;
  }

  if (url !== '' && confirm('Ready to preview?')) {
    loadPage(url, $(window).innerHeight() - 50, $(window).width() - 100);
  }

  return false;
}

/* function to display title/issue selector and then launch specific tool with title/issue ID's appended */
export function loadTitleIssueSelector() {
  const id = 'title_issue_selector_editor';
  let $modal = $(`#${id}`);

  if ($modal.length) {
    showBootstrapModal(id);
    return;
  }

  // Build Modal Container (the rest of the modal comes from ajax response)
  $modal = buildBootstrapModalContainer(id, false);

  // Load Modal Content via AJAX
  $modal.load('/titles/title_issue_selector/editor', () => {
    showBootstrapModal(id);
    initTitleIssueSelector();
  });
}

// standardize the size of the create issue modal
export function loadCreateIssueModal(href) {
  const src = `${href}?width=850&height=540&modal_iframe=true&modal=true`;
  /* eslint-disable sort-keys */
  openPopupModal2('', src, {
    className: 'thick-box-like-modal no-footer',
    header: false,
    footer: false,
    buttons: false,
  });
  /* eslint-enable sort-keys */
}

export function createHubModal() {
  CustomDomains.initialize();

  const ajax_url = '/hubs/ajax_modifyHub/0?action=create';
  ajaxBootstrapModal(ajax_url, 'choose-domain-modal');

  return false;
}

/**
 *  Modal Toolbar Links
 */
// Opens the Share Modal for the full Hub Share
export function shareFullHubModal() {
  const modalId = 'hubShareModal';
  let modalUrl = `ajax_shareHub/${AppGlobals.hubId}`;

  // Build Modal
  modalUrl = `/hubs/${modalUrl}`;
  ajaxBootstrapModal(modalUrl, modalId, () => {
    $(`#${modalId}`).remove(); // destroy modal when closed
  });
}

/**
 * Gets a User's Titles based on selected User
 *
 * @access public
 * @return void
 * @author damianr
 */
export function moveIssueToUserGetTitles() {
  const value = $('#UserUsername').val();
  if (value.search(/\([^)]+\)/) == -1) return false;

  $('#TitleTitle').removeOption(/./);
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: `/titles/ajax_getTitlesByUsername/${encodeURIComponent(
      value.replace(/\([^)]+\)/, '').trim(),
    )}`,
    // TODO: should this be corrected to `async: false` or removed?
    asyc: false,
    success(data) {
      if (data != '0') {
        $('#TitleTitle').addOption(JSON.parse(data), false);
      }
    },
  });
  /* eslint-enable sort-keys */
}

export function getIssues(titleId, callback_func, showReturnTo, domId) {
  /* eslint-disable no-param-reassign */
  if (typeof showReturnTo === 'undefined') showReturnTo = true;
  if (typeof domId === 'undefined') domId = 'IssueId';
  /* eslint-disable no-param-reassign */

  $(`#${domId}`).removeOption(/./);

  if (EMPTY.test(titleId)) return;

  // show deleted issues?
  const showDeleted = $('#IssueDeleted').is(':checked');

  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: `/titles/ajax_getIssues/${titleId}/${showDeleted}`,
    success(data) {
      if (!EMPTY.test(data) && data != 'false') {
        const parsedData = JSON.parse(data);
        if (Object.keys(parsedData).length > 0) {
          $(`#${domId}`).addOption(parsedData, false);
          if (typeof callback_func !== 'undefined' && callback_func) {
            callback_func.call();
          }

          if (showReturnTo) {
            $('#return_to_title a').attr('href', `/titles/index/${titleId}`);
          }
        }
      }
    },
  });
  /* eslint-enable sort-keys */
}

function launchSSUploadOrderSubmit(e, $modal) {
  $modal
    .find('.submit-button')
    .css('display', 'none')
    .after(
      '<a href="#" class="btn btn-primary disabled" title="This process can take up to 1 minute">Please wait...</a>',
    );
  $('#uploadsOrderForm').ajaxForm({ target: '.modal .modal-body' });
  $('#uploadsOrderForm').submit();
}

export function handleRequestUploadUpgrade() {
  window.location = 'https://hub.uberflip.com/upgrading/upgrading-your-account';
}

function updateUploadPrice() {
  $('#buy-upload-total-price').text(
    parseInt($('#UserUploads').val(), 10) * window.AppGlobals.uploadOrderPrice,
  );
}

export function launchSSUploadOrder() {
  const accountId = parseInt(window.AppGlobals.userAccountDetails.direct_customer, 10);
  const commandText = accountId > 0 ? 'Add Uploads' : 'Request Uploads';

  /* eslint-disable sort-keys */
  PopupModal2.content('/account/upload_order', {
    method: 'ajax',
    header: commandText,
    buttons: [
      { label: 'Close', promise: 'reject' },
      {
        label: commandText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: launchSSUploadOrderSubmit,
      },
    ],
    onAjaxLoad() {
      const $modal = PopupModal2.getModal();

      // update total
      if ($modal.find('#buy-upload-total-price').length) {
        updateUploadPrice();
        $modal.find('#UserUploads').on('change', updateUploadPrice);
      }

      return true; // Show footer
    },
  });
  /* eslint-enable sort-keys */
}

export function initSpectrumPicker(userPalette, changeEvent, allowEmpty) {
  // All the prettttty colours
  const defaultPalette = [
    [
      'CC0000', // Red
      '663399', // Purple
    ],
    [
      '0099CC', // light blue
      '669900', // green
    ],
    [
      'FF6600', // orange
      '996633', // brown
    ],
    [
      '000000', // black
      '999999', // grey
    ],
    ['FFFFFF'], // white
  ];

  /* eslint-disable no-param-reassign */

  // If the user sets no palette, use the default
  if (typeof userPalette === 'undefined') {
    userPalette = defaultPalette;
  }

  // Provide a blank function to be run in the picker config
  if (typeof changeEvent === 'undefined') {
    changeEvent = function () {};
  }

  // Provide a default value for this param
  if (typeof changeEvent === 'undefined') {
    allowEmpty = false;
  }

  /* eslint-enable no-param-reassign */

  // Only initialize picker if element exists
  if ($('.spectrum-picker').length) {
    // Initiates the color pickers
    $('.spectrum-picker').spectrum({
      allowEmpty,
      change: changeEvent,
      clickoutFiresChange: false,
      palette: userPalette,
      preferredFormat: 'hex6',
      showButtons: true,
      showInitial: false,
      showInput: true,
      showPalette: true,
    });
  }
}
