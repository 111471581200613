import { EMPTY } from './util';
import { getIssues } from './default';
import $ from 'jquery';

export function loadTitleIssueSelectorDestination() {
  var base_url = $('input[name=base_url]').val();
  var title_id = $('#SelectorTitleId'),
    issue_id = $('#SelectorIssueId');

  if (EMPTY.test(title_id.val())) {
    title_id.parent().addClass('error');
    title_id.focus();
  } else if (EMPTY.test(issue_id.val())) {
    issue_id.parent().addClass('error');
    issue_id.focus();
  } else {
    document.location.href = '/' + base_url + title_id.val() + '/' + issue_id.val();
  }
}

export function initTitleIssueSelector() {
  $('#loadTitleIssueBtn').on('click', function () {
    loadTitleIssueSelectorDestination();
  });

  if ($('#TitleId').length > 0 && $('#TitleId').val() > 0) {
    var title_id = $('#TitleId').val();

    $('#SelectorTitleId').val(title_id);
    getIssues(title_id, null, true, 'SelectorIssueId');
    $('#SelectorIssueId').focus();
  } else {
    $('#SelectorTitleId').focus();
  }

  $('#SelectorTitleId').change(function () {
    getIssues($(this).val(), null, true, 'SelectorIssueId');
  });
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Exposed Properties/Functions
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const exports = {
  // Functions
  initTitleIssueSelector,
  loadTitleIssueSelectorDestination,
};

export default exports;
