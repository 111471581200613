import $ from 'jquery';

/**
 * function to identify the mixed content iframe problem
 * */
export function initializeMixedContentListener() {
  const waitTime = 4000;
  const daysTillNextMessage = 1;
  let mixedAccessible = false;

  // Only need to do this is post message works (if it doesn't, mixed content problem won't exist
  // as only newer browsers)
  if (!window.postMessage || window.location.protocol !== 'https:') {
    return;
  }

  $(window).on('message', (event) => {
    if (event.originalEvent.data === 'loaded') {
      mixedAccessible = true;
    }
  });

  setTimeout(() => {
    if (
      !mixedAccessible &&
      $('iframe.mixedContent').length &&
      typeof $.cookie('mixedContentHelpShown') !== 'string'
    ) {
      $.get('/docs/ajax_getMixedContentHelp', (data) => {
        $('body').append(data);
        $('#mixedContentHelpModal').modal('show').css('z-index', '999999');
        $('.modal-backdrop').css('z-index', '999998');
        $.cookie('mixedContentHelpShown', '1', { expires: daysTillNextMessage });
      });
    }
  }, waitTime);
}
