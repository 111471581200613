import { saveProgress } from '../flash_message_box';
import $ from 'jquery';
import PopupModal2 from './PopupModalV2';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Private Vars
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
let alreadyInitialized = false;
let redirectURL;
let originalSubmitButtonText;
let domainsList = [];
let traefikKopUploadErrMsg;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Private Function Implementations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function hideModal() {
  PopupModal2.hide();
}

// Do we have another modal window open? Close it before opening any other one (Not a PopupModal instance).
function closeAnyOpenModal() {
  if ($('.modal').length > 0) {
    $('.modal').modal('hide');
  }
}

function disableModalButtons() {
  $('.modal .modal-footer .button-container button').prop('disabled', true);
}

function enableModalButtons() {
  $('.modal .modal-footer .button-container button').prop('disabled', false);
}

function handleSslTypeSelector($obj) {
  $('[data-ssl-type]').hide();
  $(`[data-ssl-type='${$obj.target.value}']`).show();
}

function initSslTypeSelector($default) {
  $('[data-ssl-type]').hide();
  // check if there is a selector
  if ($('[data-ssl-type-selector]').length) {
    // display instructions if the option is checked
    $('[data-ssl-type-selector]:checked').each((i, o) => {
      $(`[data-ssl-type='${o.value}']`).show();
    });
  } else if ($default) {
    // show uberflip instructions if there is no selector
    $("[data-ssl-type='uberflip-ssl']").show();
  }
}

function initSecondaryDomainToggle($modal) {
  const secondaryDomainToggleElement = $modal.find('#UserSecondaryDomainEnabled');

  if (secondaryDomainToggleElement) {
    const primaryDomainGroupElement = $modal.find('#PrimaryDomainGroup');
    const selectPrimaryDomainElement = primaryDomainGroupElement.find('#UserPrimaryDomainHubId');

    const updatePrimaryDomainGroupVisibility = () => {
      const isChecked = secondaryDomainToggleElement.is(':checked');
      const animate = isChecked ? 'slideDown' : 'slideUp';
      primaryDomainGroupElement[animate]();

      // Clear Primary Domain value when "Secondary Domain" is unchecked
      if (!isChecked) {
        selectPrimaryDomainElement.find(':selected').prop('selected', false);
      }
    };

    secondaryDomainToggleElement.on('click', updatePrimaryDomainGroupVisibility);
    updatePrimaryDomainGroupVisibility();
  }
}

function changeSubmitButtonText(text) {
  $('.modal-footer button.submit-button').html(text);
}

function submitSuccessCallback(data) {
  PopupModal2.hide(true);

  // Reload the current page
  if (redirectURL !== undefined && redirectURL === 'current') {
    window.location.reload();
    return;
  }

  if (redirectURL === undefined && data.response.redirectUrl !== undefined) {
    // We have a redirect URL from the server but not a local one
    redirectURL = data.response.redirectUrl;
  }

  // Redirect to redirect_url
  if (redirectURL !== undefined) {
    window.location = `/${data.response.redirectUrl}`;
  }
}

function domainContentLoaded() {
  const $modal = PopupModal2.getModal();
  const customDomainLockedEl = $modal.find('[data-locked-custom-domain]');
  const displayDefault = $modal.find('[data-ssl-default]').length;
  initSslTypeSelector(displayDefault);
  initSecondaryDomainToggle($modal);

  // Domain Locked, let's not show the footer
  if (
    customDomainLockedEl !== undefined &&
    /true/i.test(customDomainLockedEl.attr('data-locked-custom-domain'))
  ) {
    return false;
  }

  // For domain MOVE, check if we have the usedBy input.
  const customDomainUsedByEl = $modal.find('[data-custom-domain-used-by]');
  if (
    customDomainUsedByEl !== undefined &&
    /true/i.test(customDomainUsedByEl.attr('data-custom-domain-used-by'))
  ) {
    return false;
  }

  return true;
}

function submitErrorCallback(xhr) {
  enableModalButtons();
  changeSubmitButtonText(originalSubmitButtonText);

  const resp = JSON.parse(xhr.responseText);
  const errMsg = resp.meta.error.generic;

  if (resp.response && resp.response.traefikKopUploadErr) {
    traefikKopUploadErrMsg = errMsg;
    handleTraefikKopUploadErrors();
  } else {
    saveProgress('error', errMsg);
  }
}

function submitOrderForm(e, $modal) {
  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  // Validate that terms of use is checked
  const termsChecked = $modal.find('#UserConfirmed').is(':checked');
  if (termsChecked === false) {
    enableModalButtons();
    changeSubmitButtonText('Add Domain');
    saveProgress('error', 'Sorry, you must agree to the Terms of Use');
    return false;
  }

  // Get the form as an array and add in the terms checkbox value
  // as it sits in the modal footer and not the form
  const data = $modal.find('form').serializeArray();
  data.push({ name: 'data[User][confirmed]', value: $modal.find('#UserConfirmed').val() });

  const domainRedirect = $modal.find('[data-launch-domain-order]').attr('domain-redirect');
  if (domainRedirect !== undefined) {
    data.push({ name: 'data[User][redirectURL]', value: domainRedirect });
  }

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/account/ajax_processDomainOrder',
    data: $.param(data),
    dataType: 'json',
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function handleDomainOrderClick(e) {
  const $el = $($(e.currentTarget));
  redirectURL = $el.attr('data-domain-redirect');

  closeAnyOpenModal();

  originalSubmitButtonText = 'Add Domain';
  /* eslint-disable sort-keys */
  PopupModal2.content('/account/domain_order', {
    method: 'ajax',
    header: 'Add a Domain',
    footer:
      '<label class="terms-label"><input type="checkbox" name="data[User][confirmed]" id="UserConfirmed" value="yes"> I have read and agree to the Uberflip <a href="http://www.uberflip.com/terms" target="_blank">Terms of Use</a></label>',
    width: 600,
    height: 560,
    className: 'order-modal',
    onAjaxLoad: domainContentLoaded,
    buttons: [
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitOrderForm,
      },
      { label: 'Cancel', classes: 'cancel-button', promise: 'reject' },
    ],
  });
  /* eslint-enable sort-keys */
}

function submitChangeForm(e, $modal) {
  const data = $modal.find('form').serializeArray();
  if ($modal.find('input[name="data[User][sslAction]"]').val() === 'add') {
    // validate SSL certificate type
    let sslTypeSelected = false;
    $(data).each((i, field) => {
      if (field.name === 'data[User][ssl_cert_type]') {
        sslTypeSelected = true;
      }
    });
    if (sslTypeSelected === false) {
      enableModalButtons();
      changeSubmitButtonText('Add SSL');
      saveProgress('error', 'Please select who will be managing SSL certificate');
      return false;
    }
    // Validate that terms of use is checked
    const termsChecked = $modal.find('#UserConfirmed').is(':checked');
    if (termsChecked === false) {
      enableModalButtons();
      changeSubmitButtonText('Add SSL');
      saveProgress('error', 'Sorry, you must agree to the Terms of Use');
      return false;
    }
    changeSubmitButtonText('Remove SSL');

    data.push({ name: 'data[User][confirmed]', value: $modal.find('#UserConfirmed').val() });
  }

  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/account/ajax_processDomainChange',
    data: $.param(data),
    dataType: 'json',
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function submitCancelForm(e, $modal) {
  const data = $modal.find('form').serializeArray();
  // Validate that terms of use is checked
  const termsChecked = $modal.find('#UserConfirmed').is(':checked');
  if (termsChecked === false) {
    enableModalButtons();
    changeSubmitButtonText(originalSubmitButtonText);
    saveProgress('error', 'Sorry, you must check the box to delete the domain');
    return false;
  }
  data.push({ name: 'data[User][confirmed]', value: $modal.find('#UserConfirmed').val() });

  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/account/ajax_processDomainCancel',
    data: $.param(data),
    dataType: 'json',
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function submitMoveForm(e, $modal) {
  const data = $modal.find('form').serializeArray();

  // Check that there is another reseller selected
  const resellerSelected = $modal.find('#DomainsConfigResellerCustomer').val() !== '';

  if (resellerSelected === false) {
    saveProgress('error', 'Please select the user to whom the domain will be assigned');
  }

  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/account/ajax_processDomainMove',
    data: $.param(data),
    dataType: 'json',
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function handleDomainChangeClick(e) {
  const $obj = $(e.currentTarget);
  const sslAction = $obj.attr('data-sslaction');
  const domainID = $obj.attr('data-domainid');
  const domain = $obj.attr('data-domain');
  const subdomain = parseInt($obj.attr('data-subdomain'), 10);
  let footer = '';
  let controllerAction;

  // Add ssl to subdomain
  if (subdomain) {
    $.post(
      '/account/ajax_addSslToSubdomain',
      {
        action: sslAction,
        domainId: domainID,
      },
      (data) => {
        if (typeof data.response === 'object' && data.response.success) {
          if (sslAction === 'add') {
            saveProgress('success', 'Added');
          } else {
            saveProgress('success', 'Removed');
          }
          setTimeout(() => {
            window.location.reload();
          }, 700);
        }
      },
    ).fail(() => {
      saveProgress('error', `Cannot ${sslAction} SSL`);
    });

    return;
  }

  // Show popup
  if (sslAction === 'add') {
    footer =
      '<label class="terms-label"><input type="checkbox" name="data[User][confirmed]" id="UserConfirmed" value="yes"> I have read and agree to the Uberflip <a href="http://www.uberflip.com/terms" target="_blank">Terms of Use</a></label>';
    controllerAction = 'domain_add_ssl';
  } else {
    controllerAction = 'domain_remove_ssl';
  }

  originalSubmitButtonText = sslAction === 'add' ? 'Add SSL' : 'Remove SSL';
  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/${controllerAction}/${domainID}`, {
    method: 'ajax',
    header: `${sslAction === 'add' ? 'Add' : 'Remove'} Domain SSL${
      domain ? `: <small class="domain-url">${domain}</small>` : ''
    }`,
    footer,
    width: 600,
    height: 560,
    className: 'change-modal',
    onAjaxLoad: domainContentLoaded,
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitChangeForm,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function handleDomainCancelClick(e) {
  const domainID = $(e.currentTarget).attr('data-domainid');
  const domain = $(e.currentTarget).attr('data-domain');
  const controllerAction = 'domain_cancel';
  const footer =
    '<label class="terms-label"><input type="checkbox" name="data[User][confirmed]" id="UserConfirmed" value="yes"> I understand that I will lose the ability to use this domain with my services</label>';

  originalSubmitButtonText = 'Delete Domain';
  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/${controllerAction}/${domainID}`, {
    method: 'ajax',
    header: `Delete Domain${domain ? `: <small class="domain-url">${domain}</small>` : ''}`,
    footer,
    width: 600,
    height: 560,
    className: 'change-modal',
    onAjaxLoad: domainContentLoaded,
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitCancelForm,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function handleDomainMoveClick(e) {
  const domainID = $(e.currentTarget).attr('data-domainid');
  const domain = $(e.currentTarget).attr('data-domain');
  const controllerAction = 'domain_move';

  originalSubmitButtonText = 'Move Domain';
  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/${controllerAction}/${domainID}`, {
    method: 'ajax',
    header: `Move Domain${domain ? `: <small class="domain-url">${domain}</small>` : ''}`,
    footer: '',
    width: 600,
    height: 560,
    className: 'change-modal',
    onAjaxLoad: domainContentLoaded,
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitMoveForm,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function submitUploadForm(e, $modal) {
  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  const formData = new FormData();
  const cert = $modal.find('#cert').prop('files');
  const key = $modal.find('#key').prop('files');

  if (cert['length'] > 0) {
    formData.append('cert', cert[0], cert[0]['name']);
  }

  if (key['length'] > 0) {
    formData.append('key', key[0], key[0]['name']);
  }

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/ssl_certificate/ajax_uploadCertificate',
    data: formData,
    dataType: 'json',
    processData: false,
    contentType: false,
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function submitDeleteForm(e, $modal) {
  disableModalButtons();

  changeSubmitButtonText('Please wait...');

  // Validate that terms of use is checked
  const termsChecked = $modal.find('#confirm').is(':checked');
  if (termsChecked === false) {
    enableModalButtons();
    changeSubmitButtonText(originalSubmitButtonText);
    saveProgress('error', 'Sorry, you must check the box to delete the certificate');
    return false;
  }

  const formData = new FormData();
  const guid = $modal.find('#guid').val();
  formData.append('guid', guid);

  // Send the form data
  /* eslint-disable sort-keys */
  $.ajax({
    type: 'POST',
    url: '/ssl_certificate/ajax_deleteCertificate',
    data: formData,
    dataType: 'json',
    processData: false,
    contentType: false,
    success: submitSuccessCallback,
    error: submitErrorCallback,
  });
  /* eslint-enable sort-keys */
}

function handleCertificateUploadClick() {
  closeAnyOpenModal();

  originalSubmitButtonText = 'Add certificate';
  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/certificate_upload`, {
    method: 'ajax',
    header: 'Add new certificate',
    footer: '',
    width: 600,
    height: 560,
    className: 'order-modal',
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitUploadForm,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function handleCertificateDeleteClick(e) {
  closeAnyOpenModal();

  const guid = $(e.currentTarget).attr('data-guid');
  const footer =
    '<label class="terms-label"><input type="checkbox" name="confirm" id="confirm" value="yes"> Yes, I want to delete the certificate.</label>';

  originalSubmitButtonText = 'Delete';
  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/certificate_delete/${guid}`, {
    method: 'ajax',
    header: `Delete certificate`,
    footer,
    width: 600,
    height: 560,
    className: 'change-modal',
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
      {
        label: originalSubmitButtonText,
        classes: 'btn-primary submit-button',
        promise: false,
        handler: submitDeleteForm,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function populateDomainsListTable() {
  const tbody = PopupModal2.getModal().find('#domains-tbody')[0];

  for (let i = 0; i < domainsList.length; i++) {
    const url = 'https://' + domainsList[i] + '/';
    var link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.innerHTML = url;

    var row = tbody.insertRow(-1);
    var cell = row.insertCell(0);
    cell.appendChild(link);
  }

  return true;
}

function handleCertificateListDomainsClick(e) {
  closeAnyOpenModal();

  domainsList = Object.values(JSON.parse($(e.currentTarget).attr('data-domains-list')));

  const certName = $(e.currentTarget).attr('data-cert-name');

  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/certificate_list_domains`, {
    method: 'ajax',
    header: `${certName} Domains`,
    width: 600,
    height: 560,
    className: 'change-modal',
    onAjaxLoad: populateDomainsListTable,
    buttons: [
      {
        label: 'Cancel',
        classes: 'cancel-button',
        promise: 'reject',
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function handleTraefikKopUploadErrors() {
  $('.modal').modal('hide');

  /* eslint-disable sort-keys */
  PopupModal2.content(`/account/certificate_traefik_kop_upload_error`, {
    method: 'ajax',
    header: 'Certificate upload failed',
    width: 600,
    height: 560,
    className: 'change-modal',
    onAjaxLoad() {
      const el = PopupModal2.getModal().find('#traefik-kop-upload-err-msg')[0];
      el.innerHTML = traefikKopUploadErrMsg;
      return true;
    },
    buttons: [
      {
        label: 'Re-upload',
        classes: 'btn-primary submit-button',
        promise: false,
        handler: handleCertificateUploadClick,
      },
    ],
  });
  /* eslint-enable sort-keys */
}

function initialize() {
  if (alreadyInitialized) {
    return;
  }
  const $doc = $(document);
  $doc.on('click', '[data-launch-domain-order]', handleDomainOrderClick);
  $doc.on('click', '[data-launch-domain-change]', handleDomainChangeClick);
  $doc.on('click', '[data-launch-domain-cancel]', handleDomainCancelClick);
  $doc.on('click', '[data-launch-domain-move]', handleDomainMoveClick);
  $doc.on('click', '[data-launch-certificate-upload]', handleCertificateUploadClick);
  $doc.on('click', '[data-launch-certificate-delete]', handleCertificateDeleteClick);
  $doc.on('click', '[data-launch-certificate-list-domains]', handleCertificateListDomainsClick);
  $doc.on('click', '[data-hide-modal]', hideModal);
  $doc.on('click', '[data-ssl-type-selector]', handleSslTypeSelector);
  alreadyInitialized = true;
}

export default { initialize };
